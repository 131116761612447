import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import SectionHeader from 'src/components/global/section-headers/Static';
import ArticleLink from 'src/components/links/article/Article';

import styles from './Trending.module.scss';

const Trending = ({ content, header }) => (
  <div className={styles.trending}>
    <div className={styles.trending__header}>
      <SectionHeader centeredMobile priority={2}>
        {header}
      </SectionHeader>
    </div>

    {content.articles.map((article, index) => (
      <div
        className={classNames(styles.trending__item, {
          [styles.trending__item_drop]: index >= 3,
        })}
        key={uuidv4()}
      >
        <div className="media">
          <span className={styles.trending__rank}>{index + 1}</span>
          <div
            className={classNames('media-body', `${styles.trending__article}`)}
          >
            <ArticleLink
              category={article.feature.category}
              clickLocation="trending"
              clickPosition={index + 1}
              featureName={article.feature.featureName}
              featureShortName={article.feature.featureShortName}
              publishDate={article.publishDate}
              title={article.title}
            />
          </div>
        </div>
      </div>
    ))}
  </div>
);

Trending.propTypes = {
  content: PropTypes.shape({
    articles: PropTypes.arrayOf(
      PropTypes.shape({
        feature: PropTypes.shape({
          featureName: PropTypes.string.isRequired,
          featureShortName: PropTypes.string.isRequired,
        }).isRequired,
        title: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
  header: PropTypes.string.isRequired,
};

export default Trending;
