import React from 'react';
import Link from 'next/link';
import moment from 'moment';
import classNames from 'classnames';
import is from 'is_js';
import PropTypes from 'prop-types';

import { onPageNavigationEvent, placementClickEvent } from 'src/lib/analytics';
import creatorByline from 'src/lib/utilities/feature/creatorByline';

import styles from './Spotlight.module.scss';

const Spotlight = ({
  title,
  publishDate,
  category,
  featureName,
  featureShortName,
  featureImageUrl,
  creators,
  bylineOverride,
}) => {
  const imageClass =
    styles[
      `spotlight__image_${category}${is.even(moment().date()) ? 'Even' : 'Odd'}`
    ];

  return (
    <>
      <div className={classNames(styles.spotlight__mobileImage, imageClass)} />
      <div className={classNames(styles.spotlight, imageClass)}>
        <div className={styles.spotlight__contentWrapper}>
          <div className={styles.spotlight__content}>
            <div className={styles.spotlight__headshotContainer}>
              <img
                alt={featureName}
                className={styles.spotlight__headshot}
                src={featureImageUrl}
              />
            </div>
            <div className={styles.spotlight__feature}>
              <Link
                as={`/${category}/${featureShortName}`}
                href="/[category]/[shortName]"
              >
                {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                <a
                  className={classNames(styles.spotlight__featureName, {
                    [styles[`spotlight__featureName_${category}`]]: category,
                  })}
                  onClick={() =>
                    onPageNavigationEvent('hero', 'link', featureName)}
                  onKeyUp={() =>
                    onPageNavigationEvent('hero', 'link', featureName)}
                >
                  {featureName}
                </a>
              </Link>
              <div>{`by ${bylineOverride || creatorByline(creators)}`}</div>
            </div>
            <Link
              as={`/${category}/${featureShortName}/${moment(
                publishDate
              ).format('YYYY/MM/DD')}`}
              href="/[category]/[shortName]/[...publishDate]"
            >
              {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
              <a
                className={classNames(styles.spotlight__link, {
                  [styles[`spotlight__link_${category}`]]: category,
                })}
                onClick={() => {
                  onPageNavigationEvent('hero', 'link', title);
                  placementClickEvent('hero', 1, title, featureName);
                }}
                onKeyUp={() => {
                  onPageNavigationEvent('hero', 'link', title);
                  placementClickEvent('hero', 1, title, featureName);
                }}
              >
                <h2 className={styles.spotlight__headline}>{title}</h2>
              </a>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

Spotlight.propTypes = {
  bylineOverride: PropTypes.string,
  category: PropTypes.string,
  creators: PropTypes.arrayOf(
    PropTypes.shape({
      fullName: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  featureImageUrl: PropTypes.string.isRequired,
  featureName: PropTypes.string.isRequired,
  featureShortName: PropTypes.string.isRequired,
  publishDate: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

Spotlight.defaultProps = {
  category: '',
  bylineOverride: null,
};

export default Spotlight;
