import React from 'react';
import Link from 'next/link';
import moment from 'moment';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { onPageNavigationEvent, placementClickEvent } from 'src/lib/analytics';

import styles from './Article.module.scss';

const Article = ({
  title,
  publishDate,
  category,
  featureName,
  featureShortName,
  decorateCategoryColor,
  clickLocation,
  clickPosition,
}) => (
  <Link
    as={`/${category}/${featureShortName}/${moment(publishDate).format(
      'YYYY/MM/DD'
    )}`}
    href="/[category]/[shortName]/[...publishDate]"
  >
    {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
    <a
      className={classNames(styles.article, {
        [styles[`article--${category}`]]: decorateCategoryColor,
      })}
      onClick={
        clickLocation && clickPosition
          ? () => {
              onPageNavigationEvent(clickLocation, 'link', title);
              placementClickEvent(
                clickLocation,
                clickPosition,
                title,
                featureName
              );
            }
          : undefined
      }
      onKeyUp={
        clickLocation && clickPosition
          ? () => {
              onPageNavigationEvent(clickLocation, 'link', title);
              placementClickEvent(
                clickLocation,
                clickPosition,
                title,
                featureName
              );
            }
          : undefined
      }
    >
      <div
        className={classNames(styles.article__column, {
          [styles[`article__column--${category}`]]: decorateCategoryColor,
        })}
      >
        {featureName}
      </div>
      <h2 className={styles.article__title}>{title}</h2>
    </a>
  </Link>
);

Article.propTypes = {
  category: PropTypes.string.isRequired,
  clickLocation: PropTypes.string,
  clickPosition: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  decorateCategoryColor: PropTypes.bool,
  featureName: PropTypes.string.isRequired,
  featureShortName: PropTypes.string.isRequired,
  publishDate: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

Article.defaultProps = {
  decorateCategoryColor: false,
  clickLocation: '',
  clickPosition: null,
};

export default Article;
